body {
  margin: auto;
  // background: #f9f9f9;

  .homeWrap {
    padding-bottom: 12px;
    background-color: rgb(232, 237, 240);

    .fixedContainer {
      display: none;
    }

    .mainBanner {
      display: none;
    }
  }

  &.chatIsShow {
    overflow: hidden;
    pointer-events: none;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
    }
  }

  a {
    text-decoration: none;
  }

  button[class^='MuiButton-containedPrimary'] {
    background: #fddb00;
    color: #333;
    font-weight: bold;
  }

  .MuiButton-containedPrimary-180 {
    background: #fddb00;
    color: #333;
    font-weight: bold;
  }

  .MuiButton-containedPrimary-180:hover {
    background: #ffca00;
  }
}

.imp-dialog {
  background: #00000099;
}

@media screen and (max-width: 961px) {
  body {
    border: 0px;
  }
}

@media screen and (max-width: 600px) {
  html {
    height: 100vh;
  }

  body {
    border: 0px;
    height: 100vh;

    .homeWrap {
      .fixedContainer {
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        position: fixed;
        color: #333;
        z-index: 100;

        .membershipBtn {
          width: 100vw;
          padding: 0 50px 0 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #000;
          border-radius: 0px;
          background: linear-gradient(-135deg, #f04e44, #ff4e44);
          box-shadow: 1px 2px 6px #f44e0022, 0 0 3px #e9e9e9;
          color: #ffffff;
          transition: 0.3s;
          text-decoration: none;

          span {
            font-size: 16px;
            font-weight: 700;
          }

          &:hover {
            box-shadow: 2px 4px 20px #f44e0077;
            transform: translateY(-1px);
          }
        }
      }

      .mainBanner {
        display: block;
        padding: 10px 0px;
        border-top: 1px solid rgb(226, 229, 232);
        line-height: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}
