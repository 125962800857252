.orderFinishWrap {
    max-width: 1024px;
    margin: 0 auto 32px;

    .orderFinishPaper {
        margin:32px 0;
        padding:32px 32px 64px;
        min-height: 320px;
        position: relative;

        &.hide{
            display: none;
        }

        h2 {
            font-weight: normal;
        }

        ul{
            margin: 0 0 32px;
            padding:0;

            li{
                list-style: none;
                line-height: 1em;
                margin:0 0 16px;

                span{
                    display: block;
                    font-size:12px;
                    color:#666;
                    font-weight: lighter;

                    &:before{
                        content:"";
                        width:2px;
                        height:1em;
                        margin-right:4px;
                        background:#fddd00;
                        display: inline-block;
                        position: relative;
                        top:2px;
                    }
                }

                b{
                    line-height: 1em;
                    margin-left: 6px;
                    width: 100%;
                    display: block;
                    font-family: 'noto sans kr';
                }

                
            }
        }

        h3 {
            color: #005950;
        }
    }
}

@media screen and (max-width:1024px){
    .orderFinishWrap {
        max-width:768px;
    }

}
@media screen and (max-width:768px){
    .orderFinishWrap {
        .orderFinishPaper{
            margin:16px 16px 32px;
            padding:16px;

            h2 {
                font-size:20px;
                font-weight: bold;
                text-align: center;
                margin:0 0 16px;
            }
        }
    }
}