.bookingIframe {
  width: 100%;
  margin: 36px 0px;
}

@media screen and (max-width:768px){
  .bookingIframe {
    width: 100%;
    margin: 12px 0px;
  }
}