@import 'assets/scss/reset';
@import 'assets/scss/constants';

.aboutSection {
  background: #fddd00;

  .visionSection {
    max-width: $max-width;
    margin: 72px auto;
    border-bottom: 1px solid #000;

    h3 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
    }

    h2 {
      text-align: center;
      font-size: 2.4em;
      margin-bottom: 72px;
    }
  }

  .missionSection {
    margin: 0 0 128px;

    h3 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
    }

    h2 {
      text-align: center;
      font-size: 2.4em;
      margin-bottom: 32px;
    }

    div {
      text-align: center;
    }
  }

  .aboutBrandSection {
    background: #fff;
    margin: 0 auto;
    padding: 72px 0;

    h3 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
    }

    .logoImage {
      width: 100%;
    }

    .planneryImage {
      width: 85px;
      margin-top: 12px;
    }

    p {
      color: $color-dark;
      text-align: center;
      line-height: 2em;
    }
  }

  .teamJoyceSection {
    padding: 72px 0;
    background: #fce1e0;

    .innerSection {
      max-width: 1024px;
      margin: 0 auto;
    }

    h3 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
      margin-bottom: 72px;
    }

    .planner {
      .plannerCard {
        background: #fff;
        padding: 32px;
        border-radius: 8px;
        box-shadow: 0 4px 8px #f0cdcc;
      }

      img {
        width: 100%;
        border-radius: 4px;
        margin: 0 0 16px;
      }

      h3 {
        text-align: left;
        font-weight: normal;
        margin: 0;
        font-size: 16px;

        a {
          margin-left: 6px;
          border-radius: 50em;
          top: 2px;
          position: relative;
          width: 16px;
          height: 16px;
          border-radius: 50em;
          display: inline-block;
          text-align: center;
          text-decoration: none;

          &.fb {
            background: navy;
            color: #fff;
          }

          &.in {
            background: #fe4240;
            color: #fff;
          }

          &.bl {
            background: #005950;
            color: #fff;
          }
        }
      }

      h5 {
        font-weight: normal;
        margin: 8px 0 24px;
        font-size: 12px;
      }

      p {
        font-size: 13px;
        line-height: 1.6em;
        min-height: 140px;
        padding-bottom: 16px;
        text-align: justify;
        border-bottom: 1px solid #f0cdcc;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .aboutSection {
    .visionSection {
      margin: 32px auto 64px;

      h2 {
        font-size: 24px;
        margin: 0 0 64px;
      }

      h3 {
        font-size: 12px;
      }
    }

    .missionSection {
      margin: 0 0 64px;
      h2 {
        font-size: 24px;
        margin: 0 0 16px;
      }

      h3 {
        font-size: 12px;
      }

      div {
        font-size: 12px;
        margin: 0 32px;
      }
    }

    .aboutBrandSection {
      p {
        font-size: 13px;
      }

      h3 {
        font-size: 12px;
      }
      .planneryImage {
        width: 75px;
        margin-top: 12px;
      }
    }

    .teamJoyceSection {
      padding: 32px 0;

      .innerSection {
        max-width: calc(100% - 8px);
      }

      h3 {
        margin-bottom: 42px;
      }

      .planner {
        .plannerCard {
          padding: 16px;

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
