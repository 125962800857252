@import 'assets/scss/reset';

.sectionSpace {
    background: #ffe1e3;    
    padding-bottom:64px;

    h2 {
        text-align: center;
        font-size: 3em;
    }

    h4 {
        text-align: center;
        font-weight: normal;
        margin: 0 0 32px;
    }

    small {
        margin:8px 0;
        display: block;
        font-size:12px;
        color:#666;

        a {
            color:#333;
            font-weight: bold;
        }
    }

    .address {
        text-align: center;

    }

    .btnTour {
        background: transparent;
        border-radius: 50em;
        border: 2px solid #333;
        margin: 0 auto 42px;
        padding: 4px 8px !important;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        box-shadow: none;
        width:128px;
        height: 38px;

        &:hover {
            background: #fddd00;
            transition: translateY(-9px);
        }
    }

    .imgWrap {
        text-align: center;
        max-width: $max-width;
        margin: 0 auto 24px;
        padding: 32px;
        background: #fff;
        border-radius: 4px;

        img {
            width: 100%;
            display: block;
            border-radius: 4px;
        }

        div {
            font-size: 12px;
        }

        iframe {
            margin-top: 20px;
        }
    }

    .timeSection {
        background: #f9f9f9;
        margin-bottom: 32px;

        img {
            width: 100%;
            border-radius: 0;
        }



        .timeContent {
            padding: 32px;

            h2 {
                font-size: 24px;
                font-weight: bold;
                margin: 0 0 32px;
            }

            p {
                font-size: 14px;
                margin: 0 0 8px;
            }

            .worktime {
                display: flex;
                justify-content: flex-start;
                border-bottom: 2px solid #333;
                padding: 0 0 4px;
                align-items: center;
                font-weight: bold;
                font-size: 14px;
                min-width: 320px;
            }

            .button_normal{
                
            }

            a {
                font-weight: bold;
                color: #333;
            }

            ul {
                padding: 0;

                li {
                    list-style: none;
                    text-align: left;
                    margin: 0 0 4px 8px;
                    font-size: 14px;

                    &:before {
                        content: "";
                        width: 2px;
                        height: 10px;
                        background: #fddd00;
                        display: inline-block;
                        margin: 4px 4px 0 -6px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width:768px) {
    .sectionSpace {
        .timeSection {
            .timeContent {
                .worktime {
                    min-width: inherit;
                }

                button {
                    width:100%;
                    margin:8px 0;
                }
            }
        }

        .imgWrap {
            iframe {
                height:300px !important;
            }
        }
    }
}