.memberList {
  padding: 0 0 64px;
  min-height: 640px;
  margin: 0 auto;

  .memberonly {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: center;
    position: absolute;
    z-index: 3;
    margin: 0 auto;
    left: 50%;
    margin-left: -192px;
    margin-top: -100px;
    width: 320px;
    top: 50%;

    h3 {
      margin: 32px 0;
    }
  }
  .btnMembership {
    width: 320px;
    height: 48px;
    background: #fddd00;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid #00000033;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    transition: 0.3s;

    &:hover {
      transform: scale(1.01), tranlateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    color: #fff;
    margin: 0 0 32px;
  }

  .member {
    color: #005950;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    width: 240px;
    height: 320px;
    margin: 8px;
    position: relative;

    &.blur {
      -webkit-filter: blur(2px);

      .avatar {
        background: #e9e9e9;
      }

      .tooltip {
        background: #e9e9e9;
      }

      .name span,
      .company,
      .job {
        width: fit-content;
        color: #e9e9e9;
        background: #e9e9e9;
        height: 12px;
        overflow: hidden;
        margin: 4px auto !important;
        border-radius: 4px;
      }
    }

    .avatar {
      width: 140px;
      height: 140px;
      background: #fddb00;
      color: #fff;
      font-weight: 400;
      line-height: 0;
      font-size: 3rem;
      margin: 16px auto;
    }

    .tooltip {
      display: none;
      position: absolute;
      padding: 8px;
      font-size: 12px;
      line-height: 1.4em;
      background: #fddb00ee;
      transform: translateX(100%);
      margin-left: 8px;
      border-radius: 8px;
    }

    .name {
      margin: 8px auto 0;
      font-size: 16px;
      height: 24px;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .chatBtn {
        padding: 0;

        svg {
          width: 18px;
          height: 18px;
          top: 1px;
          position: relative;
          left: 4px;
        }
      }
    }

    .company {
      width: 100%;
      height: 1em;
      line-height: 1em;
      margin: 8px 0;
      text-align: center;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .job {
      height: 1em;
      line-height: 1em;
      margin: 8px 0;
      overflow: hidden;
    }

    div {
      font-size: 12px;
      text-align: center;
    }

    .job {
      margin: 0 auto;
      color: #333;
    }

    .introduce {
      color: #999;
      margin-top: 8px;
      padding: 0 16px;
      font-size: 12px;
      overflow: hidden;
      // white-space: nowrap;
      height: 2.8em;
      text-align: center;
      text-overflow: ellipsis;
      position: relative;
    }

    .sns {
      background: #ffffffee;
      width: 100%;
      bottom: 0;
      display: flex;
      border-top: 1px solid #e9e9e9;
      align-items: center;
      right: 0;
      margin: 12px auto 12px;
      justify-content: center;
      transition: 0.3s;
      flex-wrap: wrap;
      padding-top: 8px;
      position: relative;

      p {
        width: 100%;
        padding: 8px 16px;
        color: #999;
        text-align: justify;
      }

      a {
        font-size: 18px;
        color: #666;
        margin-right: 8px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #f24240;
        }
      }

      button {
        padding: 0;
        background: transparent;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .memberList:hover {
    .tooltip {
      display: block;
    }
  }
}

.searchMemberWrap {
  display: flex;
  justify-content: center;

  .searchBox {
    display: flex;
    padding: 8px;
  }

  .searchBase {
    // width: 280px;
  }
}

@media screen and (max-width: 768px) {
  .memberList {
    margin: 28px auto;

    .member {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      flex-wrap: wrap;
      width: calc(100% - 32px);
      height: auto;
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      margin: 0 8px;

      .avatar {
        width: 50px;
        height: 50px;
        margin: 0 16px 0 0;
        font-size: 1.6rem;
      }

      .content {
        flex: 2;

        .name {
          margin: 0;
          text-align: left;
          justify-content: flex-start;
        }

        .company_n_job {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .company {
            width: auto;
            height: inherit;
            max-height: 1em;
            margin: 4px 0 0;
            margin-right: 4px;
          }

          .job {
            width: 95%;
            text-align: left;
            margin: 4px 0 0;
          }

          .introduce {
            width: 95%;
            padding: 0;
            text-align: justify;
            margin: 8px 0;
          }
        }
      }

      .sns {
        margin-right: 16px;
        border: 0;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        background: transparent;
        height: 20px;
        padding: 0;
        width: 80px;
        margin: 8px 8px;
      }
    }

    .memberonly {
      left: 50%;
      margin-left: -141px;
      padding: 16px;
      margin-top: -100px;
      width: 250px;
      top: 50%;

      h3 {
        font-size: 20px;
      }
    }

    .btnMembership {
      width: 250px;
    }
  }

  .searchMemberWrap {
    margin: -48px 0;

    .searchBox {
      width: 100%;
      flex-wrap: wrap;
      position: fixed;
      top: 40px;
      z-index: 10;
      border-radius: 0;
      padding: 24px 0 0;

      hr {
        display: none;
      }
    }

    .searchBase {
      width: calc(100% - 82px);
      padding: 8px;
      margin: 8px;
      border: 1px solid #fddd00;
    }

    .input_carrier {
      width: 45%;
    }
  }
}
