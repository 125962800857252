.NoticeWrap {
  max-width: 768px;
  margin: 32px auto;
  margin-top: 0px;

  .Notice {
    margin: 16px auto 128px;
    padding: 32px;
    min-height: 400px;

    .title {
      font-size: 16px;
      color: #333;
      margin: 4px 0 24px;
      border-bottom: 2px solid#fff;
      transition: 0.3s;
      direction: inline-block;

      &:hover {
        border-bottom: 2px solid #fe4240;
      }
    }

    small {
      margin: 16px 0 0;
    }
  }
}

.NoticeDetail {
  width: 768px;
  margin: 32px auto;

  .NoticePaper {
    padding: 64px;

    .contentHeader {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 0 0 16px;

      a {
        color: #999;
        text-decoration: none;
        margin-right: 4px;
        transition: 0.3s;

        &:hover {
          color: #333;
        }
      }

      span {
        margin-left: 4px;
        color: #333;
      }
    }

    .writer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 0 0;

      .createdDate {
        color: #999;
        font-size: 12px;
      }
    }

    .customHtml {
      min-height: 335px;
      font-size: 16px;
      font-weight: 400;
      color: #000;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.8 !important;

      & > *:first-child {
        margin-top: 1em;
      }

      * {
        letter-spacing: -0.2px;
        word-spacing: 0.5px;
        margin: 0;
        word-break: break-all;
      }

      h2 {
        font-size: 28px;
        margin: 1em 0;
      }

      h3,
      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 1em;
      }

      img,
      figure img {
        max-width: 100%;
        margin: 16px auto;
        display: block;
        border-radius: 4px;
      }

      a {
        color: #2a3384;
      }
      a:hover {
        cursor: pointer;
      }
      a:after {
        font-size: small;
        content: '🔗';
        padding-left: 2px;
      }

      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        background-color: #f5f5f5;
        border-radius: 8px;
        padding: 12px 15px;
        box-sizing: border-box;
      }

      li {
        padding-left: 1.5em;
        text-indent: -1.5em;
        margin-bottom: 5px;
      }

      li:before {
        content: '✔️';
        padding-right: 8px;
      }

      ol {
        li:before {
          content: '';
          padding-right: 0px;
        }
      }

      blockquote {
        position: relative;
        margin-top: 60px;
        text-align: center;
        color: #424242;
        padding: 0px 10px;
      }

      blockquote:before {
        content: '';
        position: absolute;
        background: url('../../../src/images/quote_icon.png'); /*url of image*/
        left: calc(50% - 16px);
        top: -42px;
        font-size: 100px;
        width: 32px;
        height: 28px;
      }

      .ql-align-right {
        text-align: right;
      }

      .ql-align-center {
        text-align: center;
      }

      width: 100%;

      .ql-video {
        width: 420px;
        max-width: 90%;
        height: 260px;
      }
      .ql-video.ql-align-center {
        display: flex;
        margin: 0 auto;
      }
      .ql-video.ql-align-right {
        display: flex;
        margin-left: auto;
      }
    }
    .pagination {
      border-top: 1px solid #e9e9e9;
      padding-top: 32px;

      a {
        margin: 0 8px;
        color: #333;
        transition: 0.3s;

        &:hover {
          color: #fe4240;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .NoticeWrap {
    margin: 18px;
    margin-top: 0px;

    .Notice {
      padding: 16px 0;
    }
  }

  .NoticeDetail {
    width: inherit;
    margin: 16px 16px;

    .NoticePaper {
      padding: 16px;

      h1 {
        font-size: 24px;
      }
    }
  }
}
