.formContainer {
  .notice {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      padding: 10px 40px;
      background-color: #ff4e44;
      color: #fff;
      font-size: 20px;
    }
  }

  header {
    h2 {
      text-align: center;
    }
  }

  .iframeContainer {
    width: 100%;
    margin: 32px 0 32px;
  }
}
