footer {
  // border-top: 1px solid rgba(99, 99, 99, 0.15);
}

.iosFooterWrap {
  background: #fff !important;
  width: 64px;
  z-index: 10001;
  position: fixed !important;
  top: 0;
  left: 0;
  justify-content: space-around !important;
  display: flex;

  .footerBack {
    color: #333;
    width: 60px;
    height: 52px;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-top: 42px;
    //styleName: Title 3;
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #93928e;
  }

  a {
    width: 64px;
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    small {
      font-size: 10px;
    }
  }
}

.footerWrapper {
  max-width: 1230px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  padding: 32px 0 56px 0;
  margin-bottom: 90px;

  h3 {
    //styleName: Title 3;
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #93928e;
    margin-top: 42px;
  }

  img {
    // margin-top: 15px;
    // width: 100px;
    // margin-left: 5px;
    height: 24px;
  }

  ul {
    margin: 0 0 12px;
    padding: 0;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 550px;

    // & > li:not(:first-child) {
    //   margin-left: 4px;
    // }

    li {
      list-style: none;
      margin: 0 0 8px;
      //styleName: Br/Body 3;
      font-family: Noto Sans KR;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #93928e;

      span {
        font-family: Noto Sans KR;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #93928e;
      }

      a {
        font-family: Noto Sans KR;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #93928e;
      }
    }

    li:nth-child(1)::after,
    li:nth-child(2)::after,
    li:nth-child(5)::after {
      content: '|';
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .footerNav {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    a {
      //styleName: Title 4;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #93928e;
      margin-right: 24px;
    }
  }

  .footerButtons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 68px;

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border: none;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f5f5f0;

      span {
        //styleName: Middle;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #000000;
      }
    }

    button:first-child {
      margin-bottom: 16px;
    }
  }

  .sns {
    position: absolute;
    top: 63px;
    right: 0px;

    a {
      img {
        width: 32px;
        height: 32px;
      }
    }

    a:not(:last-child) {
      margin-right: 16px;
    }
  }

  .copyright {
    display: block;
    margin-top: 35px;
    //styleName: Samll;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #93928e;
  }
}

.footerWrapper.darkMode {
  background: #000000;

  .footerButtons {
    button,
    a {
      background: #000000;

      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      span {
        color: white;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .footerWrapper {
    padding: 20px;
    padding-bottom: 60px;

    ul {
      width: 350px;

      li:nth-child(2)::after,
      li:nth-child(5)::after {
        content: '';
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .footerButtons {
      position: relative;
      bottom: 0;
      margin-top: 12px;
    }

    .footerNav {
      flex-direction: column;

      a {
        width: fit-content;
      }

      a:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .sns {
      position: relative;
      top: 0px;
      margin-top: 40px;

      a {
        margin: 0px;
      }
    }

    h3 {
      //styleName: Title 3;
      font-family: Noto Sans KR;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }

  .copyright {
    margin: 24px 0px 36px;
  }
}

@media screen and (max-width: 450px) {
  .footerWrapper {
  }
}
