.policyWrap {
  max-width: 935px;
  min-height: 600px;
  margin: 0 auto;

  .policyPaper {
    padding: 32px;
    margin: 32px 0;

    .public-DraftEditor-content div {
      margin: 0 0 32px;
    }
  }

  .menuHeader {
    padding-top: 16px;
    text-align: center;
  }

  .content p:first-child {
    margin-top: 0px;
  }

  ul {
    padding: 8px 32px;
    background: #f9f9f9;
    border: 1px solid #ccc;

    ul {
      border: none;
      background: none;
    }
  }

  table {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    border-spacing: 0;

    thead {
      th {
        background: #f9f9f9;
        color: #333;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        font-size: 12px;
        padding: 4px 8px;
      }
    }

    tbody {
      td {
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        padding: 8px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .policyWrap {
    margin: 0 16px;

    .policyPaper {
      padding: 32px;
      margin: 16px 0;
    }

    .menuHeader {
      padding: 0px;
      text-align: center;
    }
  }
}
