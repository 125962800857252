.carousel__dot-group {
  position: absolute;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
  z-index: 10;
  pointer-events: none;
  transition: opacity 300ms ease 0s;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.carousel__dot {
  margin: 0 3px;
  padding: 0px;
  width: 6px;
  height: 6px;
  background: rgb(0, 0, 0);
  background-color: #999999;
  display: inline-block;
  opacity: 0.2;
  pointer-events: auto;
  border: 0px;
  border-radius: 6px;
  opacity: 0.56;
  cursor: pointer;
}

.carousel__dot--selected {
  width: 28px;
  background-color: rgb(200, 200, 200);
  opacity: 1;
}
