// Global Values

// Color - Palette
$color-primary: #fddb00;
$color-secondary: #f04e44;
$color-tertiary: #2a3384;

// Color - Monochrome
$color-lightest: #ffffff;
$color-lighter: #f8f8f8;
$color-light: #f3f3f3;
$color-mediumlight: #eaeaea;
$color-medium: #dddddd;
$color-mediumdark: #999999;
$color-dark: #666666;
$color-darker: #444444;
$color-darkest: #3c3c3c;

// Color - Others
$color-border: rgba(0, 0, 0, 0.1);

// Color - Palette
$color-primary: #fddb00;
$color-secondary: #f04e44;
$color-tertiary: #2a3384;

// Color - Monochrome
$color-lightest: #ffffff;
$color-lighter: #f8f8f8;
$color-light: #f3f3f3;
$color-mediumlight: #eaeaea;
$color-medium: #dddddd;
$color-mediumdark: #999999;
$color-dark: #666666;
$color-darker: #444444;
$color-darkest: #3c3c3c;

// Color - Others
$color-border: rgba(0, 0, 0, 0.1);

// Space - Inset
$space-inset-default: 16px 16px 16px 16px;
$space-inset-xs: 4px 4px 4px 4px;
$space-inset-s: 8px 8px 8px 8px;
$space-inset-m: 16px 16px 16px 16px;
$space-inset-l: 32px 32px 32px 32px;
$space-inset-xl: 64px 64px 64px 64px;

// Space - Inset - Squish
$sapce-inset-squish-s: 4px 8px;
$sapce-inset-squish-m: 8px 16px;
$sapce-inset-squish-l: 16px 32px;

// Space - Inset - Stretch
$sapce-inset-stretch-s: 12px 8px;
$sapce-inset-stretch-m: 24px 16px;

// Space - Stack
$space-stack-xxs: 2px;
$space-stack-xs: 4px;
$space-stack-s: 8px;
$space-stack-m: 16px;
$space-stack-l: 24px;
$space-stack-xl: 32px;
$space-stack-xxl: 64px;

// Space - Inline
$space-inline-xxs: 2px;
$space-inline-xs: 4px;
$space-inline-s: 8px;
$space-inline-m: 16px;
$space-inline-l: 24px;
$space-inline-xl: 32px;
$space-inline-xxl: 64px;
